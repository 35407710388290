<template>
  <b-row class="pt-1">
    <b-col>
      <b-row class="pb-1">
        <b-col>
          <b-form-checkbox
            id="previousSurveillanceConducted"
            v-model="HasPreviousSurveillanceConducted"
            name="requireAuthentication"
          >
            Previous Surveillance Conducted
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Conducted By"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedConductedBy}"
            >
              <textarea
                v-model="PreviousSurveillanceConductedBy"
                class="expandable-textarea"
                :class="[isExpandedConductedBy ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedConductedBy = !isExpandedConductedBy">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedConductedBy ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Previous Surveillance Results"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedResults}"
            >
              <textarea
                v-model="PreviousSurveillanceResults"
                class="expandable-textarea"
                :class="[isExpandedResults ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedResults = !isExpandedResults">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedResults ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Reason for Assignment"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedSurveillance}"
            >
              <textarea
                v-model="ReasonForSurveillance"
                class="expandable-textarea"
                :class="[isExpandedSurveillance ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedSurveillance = !isExpandedSurveillance">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedSurveillance ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1 pb-1">
        <b-col>
          <b-form-group
            label="Notes Regarding This Assignment"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedAssignment}"
            >
              <textarea
                v-model="NotesRegardingThisAssignment"
                class="expandable-textarea"
                :class="[isExpandedAssignment ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedAssignment = !isExpandedAssignment">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedAssignment ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BFormGroup, BRow} from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
  },
  data() {
    return {
      SurveillanceConducted: null,
      HasPreviousSurveillanceConducted: false,
      PreviousSurveillanceConductedBy: "",
      PreviousSurveillanceResults: "",
      ReasonForSurveillance: "",
      NotesRegardingThisAssignment: "",

      isExpandedConductedBy: false,
      isExpandedResults: false,
      isExpandedSurveillance: false,
      isExpandedAssignment: false,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "publicCase/getCase",
    }),
    getCaseData() {
      return this.getCase
    },
  },
  watch: {
    getCaseData(val) {this.fillCaseNotes(val);},
    HasPreviousSurveillanceConducted(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, HasPreviousSurveillanceConducted: val}},
    PreviousSurveillanceResults(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, PreviousSurveillanceResults: val}},
    PreviousSurveillanceConductedBy(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, PreviousSurveillanceConductedBy: val}},
    ReasonForSurveillance(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, ReasonForSurveillance: val}},
    NotesRegardingThisAssignment(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, NotesRegardingThisAssignment: val}},

    SurveillanceConducted(val) {
      this.$emit("changedSurveillanceConducted", this.SurveillanceConducted);
    },
  },
  created() {
    this.fillCaseNotes(this.getCaseData);
  },
  methods: {
    fillCaseNotes(res) {
      this.HasPreviousSurveillanceConducted = res.HasPreviousSurveillanceConducted;
      this.PreviousSurveillanceResults = res.PreviousSurveillanceResults;
      this.ReasonForSurveillance = res.ReasonForSurveillance;
      this.NotesRegardingThisAssignment = res.NotesRegardingThisAssignment;
      this.PreviousSurveillanceConductedBy = res.PreviousSurveillanceConductedBy;
    }
  }
}
</script>

<style scoped>
p {
  font-size: .857rem;
}
</style>