<template>
  <div class="pl-1 pr-1">
    <b-row>
      <b-col md="5">
        <validation-provider
          #default="{ errors }"
          name="Street Address 1"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Street Address 1"
            label-for="streetAddress"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              id="streetAddress"
              v-model="Address1"
              placeholder=""
              name="streetAddress"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="City 1"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="City 1"
            label-for="city"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              id="city"
              v-model="City1"
              placeholder=""
              name="city"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="State 1"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="State 1"
            label-for="state"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="state"
              v-model="StateProvince1"
              label="title"
              :options="StatesList"
              placeholder=""
              name="state"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Zip 1"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Zip 1"
            label-for="zip"
            :state="errors.length > 0 ? false : null"
          >
            <ZipInput
              id="zip"
              v-model="PostalCode1"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Address Description 1"
          label-for="description"
        >
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpanded}"
          >
            <textarea
              v-model="Description1"
              class="expandable-textarea"
              :class="[isExpanded ? 'not-expanded' : 'expanded']"
              contentEditable
            />
            <div @click="isExpanded = !isExpanded">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpanded ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <b-form-group
          label="Street Address 2"
          label-for="streetAddress2"
        >
          <b-form-input
            id="streetAddress2"
            v-model="Address2"
            placeholder=""
            name="streetAddress2"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="City 2"
          label-for="city2"
        >
          <b-form-input
            id="city2"
            v-model="City2"
            placeholder=""
            name="city2"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="State 2"
          label-for="state2"
        >
          <v-select
            id="state2"
            v-model="StateProvince2"
            label="title"
            :options="StatesList"
            placeholder=""
            name="state2"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Zip 2"
          label-for="zip2"
        >
          <ZipInput
            id="zip2"
            v-model="PostalCode2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Address Description 2"
          label-for="description2"
        >
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpanded2}"
          >
            <textarea
              v-model="Description2"
              class="expandable-textarea"
              :class="[isExpanded2 ? 'not-expanded' : 'expanded']"
              contentEditable
            />
            <div @click="isExpanded2 = !isExpanded2">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpanded2 ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import ZipInput from "@core/components/zipInput/ZipInput.vue";
import {ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ZipInput,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      SubjectAddress: {},
      Address1: "",
      City1: "",
      StateProvince1: "",
      PostalCode1: "",
      isExpanded: false,
      Description1: "",
      Address2: "",
      City2: "",
      StateProvince2: "",
      PostalCode2: "",
      isExpanded2: false,
      Description2: "",

      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
    }
  },
  computed: {
    ...mapGetters({
      getSubjectAddress: "publicCase/getSubjectAddress",
    }),
    getSubjectAddressData() {
      return this.getSubjectAddress
    },
  },
  watch: {
    getSubjectAddressData(val) {this.fillSubject(val);},
    Address1(val) {this.SubjectAddress = {...this.SubjectAddress, Address1: val}},
    City1(val) {this.SubjectAddress = {...this.SubjectAddress, City1: val}},
    StateProvince1(val) {this.SubjectAddress = {...this.SubjectAddress, StateProvince1: val}},
    PostalCode1(val) {this.SubjectAddress = {...this.SubjectAddress, PostalCode1: val}},
    Description1(val) {this.SubjectAddress = {...this.SubjectAddress, Description1: val}},
    Address2(val) {this.SubjectAddress = {...this.SubjectAddress, Address2: val}},
    City2(val) {this.SubjectAddress = {...this.SubjectAddress, City2: val}},
    StateProvince2(val) {this.SubjectAddress = {...this.SubjectAddress, StateProvince2: val}},
    PostalCode2(val) {this.SubjectAddress = {...this.SubjectAddress, PostalCode2: val}},
    Description2(val) {this.SubjectAddress = {...this.SubjectAddress, Description2: val}},
    SubjectAddress(val) {
      this.$emit("changeSubjectAddress", val);
    }
  },
  created() {
    this.fillSubject(this.getSubjectAddressData);
  },
  methods: {
    fillSubject(res) {
      this.Address1 = res.Address1;
      this.City1 = res.City1;
      this.StateProvince1 = res.StateProvince1;
      this.PostalCode1 = res.PostalCode1;
      this.Description1 = res.Description1;
      this.Address2 = res.Address2;
      this.City2 = res.City2;
      this.StateProvince2 = res.StateProvince2;
      this.PostalCode2 = res.PostalCode2;
      this.Description2 = res.Description2;
    }
  }
}
</script>

<style scoped>

</style>