<template>
  <b-row class="pb-2 pt-2">
    <b-col>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Date of Injury / Loss"
            label-for="dateInjury"
          >
            <b-form-input
              id="dateInjury"
              v-model="InjuryDate"
              type="date"
              placeholder=""
              name="dateInjury"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Description of Injury / Loss"
            label-for="descriptionInjury"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedInjuryDesc}"
            >
              <textarea
                v-model="InjuryDesc"
                class="expandable-textarea"
                :class="[isExpandedInjuryDesc ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedInjuryDesc = !isExpandedInjuryDesc">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedInjuryDesc ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col>
          <b-form-group
            label="Medical Restrictions"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedMedicalRestrictions}"
            >
              <textarea
                v-model="MedicalRestrictions"
                class="expandable-textarea"
                :class="[isExpandedMedicalRestrictions ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedMedicalRestrictions = !isExpandedMedicalRestrictions">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedMedicalRestrictions ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <b-form-group
            label="Red Flags"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedRedFlags}"
            >
              <textarea
                v-model="RedFlags"
                class="expandable-textarea"
                :class="[isExpandedRedFlags ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedRedFlags = !isExpandedRedFlags">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedRedFlags ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <b-form-group
            label="Employment Info / Current Work Status"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedWorkStatus}"
            >
              <textarea
                v-model="EmploymentInfoWorkStatus"
                class="expandable-textarea"
                :class="[isExpandedWorkStatus ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedWorkStatus = !isExpandedWorkStatus">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedWorkStatus ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group>
            <b-form-checkbox
              id="representedAttorney"
              v-model="HasAttorneyRepresentation"
              name="representedAttorney"
            >
              Represented by Attorney
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Claimant Attorney"
          >
            <b-form-input
              id="who"
              v-model="AttorneyRepInfo"
              placeholder=""
              name="who"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group>
            <b-form-checkbox
              id="scheduledAppointments"
              v-model="ScheduledAppts"
              name="scheduledAppointments"
            >
              Scheduled Appointments
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Upcoming Appointments Dates, Times, and Locations"
          >
            <div class="white-bg-container border-gray">
              <Appointment
                v-for="(appointment, index) of Appointments"
                :key="index"
                :index="index"
                class="mb-1"
                @updateAppointment="updateAppointment"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Appointment from "@/views/cases/components/Appointment";
import {mapGetters} from "vuex";

export default {
  components: {
    Appointment,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      ClaimDetail: {},
      InjuryDate: "",
      InjuryDesc: "",
      MedicalRestrictions: "",
      RedFlags: "",
      EmploymentInfoWorkStatus: "",
      HasAttorneyRepresentation: false,
      ScheduledAppts: false,
      AttorneyRepInfo: "",
      Appointments: [{
        ScheduledApptWhen: "",
        ScheduledApptWhere: "",
      }],

      isExpandedInjuryDesc: false,
      isExpandedMedicalRestrictions: false,
      isExpandedRedFlags: false,
      isExpandedWorkStatus: false,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "publicCase/getCase",
    }),
    getClaimDetailData() {
      return this.getCase
    },
  },
  watch: {
    getClaimDetailData(val) {
      this.fillClaimDetail(val);
    },
    InjuryDate(val) {this.ClaimDetail = {...this.ClaimDetail, InjuryDate: val}},
    InjuryDesc(val) {this.ClaimDetail = {...this.ClaimDetail, InjuryDesc: val}},
    MedicalRestrictions(val) {this.ClaimDetail = {...this.ClaimDetail, MedicalRestrictions: val}},
    RedFlags(val) {this.ClaimDetail = {...this.ClaimDetail, RedFlags: val}},
    EmploymentInfoWorkStatus(val) {this.ClaimDetail = {...this.ClaimDetail, EmploymentInfoWorkStatus: val}},
    HasAttorneyRepresentation(val) {this.ClaimDetail = {...this.ClaimDetail, HasAttorneyRepresentation: val}},
    ScheduledAppts(val) {this.ClaimDetail = {...this.ClaimDetail, ScheduledAppts: val}},
    AttorneyRepInfo(val) {this.ClaimDetail = {...this.ClaimDetail, AttorneyRepInfo: val}},
    Appointments(val) {this.ClaimDetail = {...this.ClaimDetail, Appointments: val}},

    ClaimDetail() {
      this.$emit("changeClaimDetail", this.ClaimDetail);
    }
  },
  async created() {
    try {
      this.fillClaimDetail(this.getClaimDetailData);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    updateAppointment(val, index) {
      this.Appointments.splice(index, 1, val);
    },
    fillClaimDetail(val) {
      this.InjuryDate = val.InjuryDate && val.InjuryDate.substr(0, 10);
      this.InjuryDesc = val.InjuryDesc;
      this.MedicalRestrictions = val.MedicalRestrictions;
      this.RedFlags = val.RedFlags;
      this.EmploymentInfoWorkStatus = val.EmploymentInfoWorkStatus;
      this.HasAttorneyRepresentation = val.HasAttorneyRepresentation;
      this.ScheduledAppts = val.ScheduledAppts;
      this.AttorneyRepInfo = val.AttorneyRepInfo;
      this.Appointments = val.Appointments;
    }
  }
}
</script>

<style scoped>
.border-gray {
  border: 1px solid #c7c7cc !important
}
p {
  font-size: 0.857rem;
}
</style>