<template>
  <div>
    <b-row>
      <b-col md="9">
        <validation-provider
          #default="{ errors }"
          name="Client Name"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Your Company Name"
            label-for="clientName"
            :state="errors.length > 0 ? false : null"
          >

            <b-form-input
              id="clientName"
              v-model="ClientName"
              type="text"
              placeholder=""
              name="clientName"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <validation-provider
          #default="{ errors }"
          name="Your Name"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Your Name"
            label-for="requesterName"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              id="requesterName"
              v-model="RequesterName"
              type="text"
              placeholder=""
              name="requesterName"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-form-group
          label="Your Email"
          label-for="requesterEmail"
          class="required-data"
        >
          <b-form-input
            id="requesterEmail"
            v-model="RequesterEmail"
            placeholder=""
            name="requesterEmail"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-form-group
          label="Your Phone"
          label-for="requesterPhone"
        >
          <b-form-input
            id="requesterPhone"
            v-model="RequesterPhone"
            placeholder=""
            name="requesterPhone"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Any Email’s to CC on Case Correspondence"
          label-for="requesterEmail"
        >
          <b-form-input
            id="ccEmail"
            v-model="CcEmails"
            placeholder=""
            name="ccEmail"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import {BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ClientInfo: {},
      ClientName: "",
      RequesterName: "",
      RequesterEmail: "",
      RequesterPhone: "",
      CcEmails: "",
    }
  },
  computed: {
    ...mapGetters({
      getCase: "publicCase/getCase",
    }),
    getCaseData() {
      return this.getCase;
    }
  },
  watch: {
    getCaseData(val) {this.fillClientInfo(val)},
    ClientName(val) {this.ClientInfo = {...this.ClientInfo, ClientName: val}},
    RequesterName(val) {this.ClientInfo = {...this.ClientInfo, RequesterName: val}},
    RequesterEmail(val) {this.ClientInfo = {...this.ClientInfo, RequesterEmail: val}},
    RequesterPhone(val) {this.ClientInfo = {...this.ClientInfo, RequesterPhone: val}},
    CcEmails(val) {this.ClientInfo = {...this.ClientInfo, CcEmails: val}},
    ClientInfo() {
      this.$emit("changeClientInfo", this.ClientInfo);
    }
  },
  created() {
    this.fillClientInfo(this.getCaseData)
  },
  methods: {
    fillClientInfo(res) {
      this.ClientName = res.ClientName;
      this.RequesterName = res.RequesterName;
      this.RequesterEmail = res.RequesterEmail;
      this.RequesterPhone = res.RequesterPhone;
      this.CcEmails = res.CcEmails;
    }
  }
}
</script>

<style scoped>

</style>