<template>
  <b-row class="p-1">
    <b-col>
      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  class="required-data"
                  label="First Name"
                  label-for="firstName"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="firstName"
                    v-model="FirstName"
                    placeholder=""
                    name="firstName"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Middle Name"
                label-for="middleName"
              >
                <b-form-input
                  id="middleName"
                  v-model="MiddleName"
                  placeholder=""
                  name="middleName"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  class="required-data"
                  label="Last Name"
                  label-for="lastName"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="lastName"
                    v-model="LastName"
                    placeholder=""
                    name="lastName"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Alias"
                label-for="alias"
              >
                <b-form-input
                  id="alias"
                  v-model="Alias"
                  placeholder=""
                  name="alias"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="SSN"
                label-for="ssn"
              >
                <b-input-group>
                  <b-form-input
                    id="ssn"
                    v-model="SocialSecurityNo"
                    v-mask="'###-##-####'"
                    :type="passwordFieldType"
                    :readonly="passwordFieldType === 'password'"
                    placeholder=""
                    name="ssn"
                  />
                  <div class="ice-icon">
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="DOB"
                label-for="dob"
              >
                <b-form-input
                  id="dob"
                  v-model="DateOfBirth"
                  type="date"
                  placeholder=""
                  name="dob"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Driver’s License No."
                label-for="driversLicenseNo"
              >
                <b-form-input
                  id="driversLicenseNo"
                  v-model="DriversLicenseNo"
                  placeholder=""
                  name="driversLicenseNo"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Driver’s License State"
                label-for="state"
              >
                <v-select
                  id="state"
                  v-model="DriversLicenseState"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="StatesList"
                  placeholder=""
                  name="state"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="9">
          <b-row>
            <b-col
              md="3"
            >
              <b-form-group
                label="Phone 1"
                label-for="phone1"
              >
                <b-form-input
                  id="phone1"
                  v-model="Phone1"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="phone1"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" class="pt-2">
              <b-form-group>
                <b-form-checkbox
                  id="work"
                  v-model="Work1"
                  name="work"
                  inline
                >
                  Work
                </b-form-checkbox>
                <b-form-checkbox
                  id="cell"
                  v-model="Cell1"
                  name="cell"
                  inline
                >
                  Cell
                </b-form-checkbox>
                <b-form-checkbox
                  id="home"
                  v-model="Home1"
                  name="home"
                  inline
                >
                  Home
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Phone 2"
                label-for="phone2"
              >
                <b-form-input
                  id="phone2"
                  v-model="Phone2"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="phone2"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" class="pt-2">
              <b-form-group>
                <b-form-checkbox
                  id="work2"
                  v-model="Work2"
                  name="work2"
                  inline
                >
                  Work
                </b-form-checkbox>
                <b-form-checkbox
                  id="cell2"
                  v-model="Cell2"
                  name="cell2"
                  inline
                >
                  Cell
                </b-form-checkbox>
                <b-form-checkbox
                  id="home2"
                  v-model="Home2"
                  name="home2"
                  inline
                >
                  Home
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import APIService from "@core/utils/APIService";
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import {ValidationProvider} from "vee-validate";

const apiService = new APIService();

export default {
  delimiters: ["${", "}"],
  components: {
    ValidationProvider,
    BInputGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Alias: "",
      SocialSecurityNo: "",
      DateOfBirth: "",
      DOBForInput: "",
      DriversLicenseNo: "",
      DriversLicenseState: "",
      Subject: {},
      matchCaseID: "",

      Phone1: "",
      Phone1Type: "",
      Phone2: "",
      Phone2Type: "",

      Work1: false,
      Cell1: false,
      Home1: false,

      Work2: false,
      Cell2: false,
      Home2: false,

      phoneType: ["Work", "Cell", "Home"],
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
      passwordFieldType: "password",
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "publicCase/getSubject",
      getSubjectAddress: "publicCase/getSubjectAddress",
    }),
    passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    getSubjectData() {
      return this.getSubject
    },
  },
  watch: {
    Work1(val) {this.setPhoneType(val, "Work1")},
    Cell1(val) {this.setPhoneType(val, "Cell1")},
    Home1(val) {this.setPhoneType(val, "Home1")},
    Work2(val) {this.setPhoneType(val, "Work2")},
    Cell2(val) {this.setPhoneType(val, "Cell2")},
    Home2(val) {this.setPhoneType(val, "Home2")},

    getSubjectData(val) {this.fillSubject(val);},
    FirstName(val) {this.Subject = {...this.Subject, FirstName: val}},
    MiddleName(val) {this.Subject = {...this.Subject, MiddleName: val}},
    LastName(val) {this.Subject = {...this.Subject, LastName: val}},
    Alias(val) {this.Subject = {...this.Subject, Alias: val}},
    SocialSecurityNo(val) {this.Subject = {...this.Subject, SocialSecurityNo: val}},
    DateOfBirth(val) {this.Subject = {...this.Subject, DateOfBirth: val}},
    DriversLicenseNo(val) {this.Subject = {...this.Subject, DriversLicenseNo: val}},
    DriversLicenseState(val) {this.Subject = {...this.Subject, DriversLicenseState: val}},
    Phone1(val) {this.Subject = {...this.Subject, Phone1: val}},
    Phone1Type(val) {this.Subject = {...this.Subject, Phone1Type: val}},
    Phone2(val) {this.Subject = {...this.Subject, Phone2: val}},
    Phone2Type(val) {this.Subject = {...this.Subject, Phone2Type: val}},
    Subject() {
      this.$emit("changeSubject", {...this.Subject, SubjectAddress: this.getSubjectAddress});
    },
  },
  created() {
    this.fillSubject(this.getSubject);
  },
  methods: {
    setPhoneType(val, name) {
      const isFirst = name.indexOf("1") !== -1
      const isSecond = name.indexOf("2") !== -1
      if (isFirst) {
        if (val && name) {
          if (this.Phone1Type) this[this.Phone1Type + "1"] = false
          this.Phone1Type = name.substring(0, name.length - 1);
        } else if (name.substring(0, name.length - 1) === this.Phone1Type && !val) {
          this.Phone1Type = ""
        }
      } else if (isSecond) {
        if (val && name) {
          if (this.Phone2Type) this[this.Phone2Type + "2"] = false
          this.Phone2Type = name.substring(0, name.length - 1);
        } else if (name.substring(0, name.length - 1) === this.Phone2Type) {
          this.Phone2Type = ""
        }
      }
    },
    fillSubject(val) {
      const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
      this.DateOfBirth = val.DateOfBirth
      this.SubjectID = val.SubjectID;
      this.FirstName = val.FirstName;
      this.MiddleName = val.MiddleName;
      this.LastName = val.LastName;
      this.Alias = val.Alias;
      this.SocialSecurityNo = val.SocialSecurityNo;
      this.DriversLicenseNo = val.DriversLicenseNo;
      this.DriversLicenseState = val.DriversLicenseState;
      this.Phone1 = val.Phone1;
      this.Phone1Type = val.Phone1Type;
      this.Phone2 = val.Phone2;
      this.Phone2Type = val.Phone2Type;
    },
  },
}
</script>

<style scoped>
.ice-icon {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    z-index: 3;
}
</style>