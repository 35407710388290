<template>
  <div class="uploader-wrapper">
    <VueFileAgent
      v-model="fileRecords"
      :multiple="true"
    />
    <FileUploadRow
      v-for="(file, index) in fileRecords"
      :key="index"
      :file="file"
      :index="index"
      public-case
      class="pl-2 font-small-3"
      @removeFile="remove"
      @changeData="changeData"
    />
  </div>
</template>

<script>
import FileUploadRow from "@/views/cases/components/FileUploadRow";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    FileUploadRow,
  },
  data() {
    return {
      fileRecords: [],
      Files: [],
    }
  },
  watch: {
    fileRecords(val) {
      if (val.length <= 5) {
          val.map((f, index) => {
              if (f.size < 10000000) {
                  let duplicate = false;
                  const item = {
                      file: f,
                      data: "",
                  }
                  this.Files.map(i => {
                      if (i.file.file.name === f.file.name && !duplicate) {
                          duplicate = true
                      }
                  })
                  if (!duplicate) this.Files.push(item);
                  return this.Files;
              } else {
                  this.showToast('danger', 'top-center', 4000, JSON.stringify('A file cannot be bigger than 10Meg.'))
                  this.fileRecords.splice(index, 1);
              }
          })
      }  else {
          this.fileRecords.splice(val.length - 1, 1);
          this.showToast('danger', 'top-center', 4000, JSON.stringify('Cannot have more than 5 attachments'))
      }
    },
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
          },
      },
      {
          position,
          timeout,
      })
    },
    remove(i) {
      this.Files.splice(i, 1);
      this.fileRecords.splice(i, 1);
      this.$emit("uploadFiles", this.Files);
    },
    changeData(i, data) {
      const item = {
        file: this.Files[i].file,
        data,
      }
      this.Files.splice(i, 1, item)
      this.$emit("uploadFiles", this.Files);
    }
  },
}
</script>

<style scoped>
.uploader-wrapper {
}
</style>