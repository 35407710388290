<template>
  <b-row class="pl-1 pr-1 pb-1">
    <b-col>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                label="Sex"
                label-for="sex"
              >
                <b-form-checkbox
                  id="male"
                  v-model="SexMale"
                  name="male"
                  inline
                >
                  Male
                </b-form-checkbox>
                <b-form-checkbox
                  id="female"
                  v-model="SexFemale"
                  name="female"
                  inline
                >
                  Female
                </b-form-checkbox>
                <b-form-checkbox
                  id="unknown"
                  v-model="SexUnknown"
                  name="unknown"
                  inline
                >
                  Unknown
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Height"
                label-for="height"
              >
                <div class="d-flex">
                  <span class="d-flex align-items-baseline height-input">
                    <b-form-input
                      id="height"
                      v-model="HeightFt"
                      placeholder=""
                      name="heightFt"
                      class="mr-1"
                    />
                    <p>ft</p>
                  </span>
                  <span class="d-flex align-items-baseline height-input">
                    <b-form-input
                      v-model="HeightIn"
                      placeholder=""
                      name="heightIn"
                      class="mr-1 ml-1"
                    />
                    <p>in</p>
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Weight"
                label-for="weight"
              >
                <b-form-input
                  id="weight"
                  v-model="Weight"
                  placeholder=""
                  name="weight"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Martial Status"
                label-for="married"
              >
                <b-form-input
                  id="married"
                  v-model="Spouse"
                  placeholder=""
                  name="married"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Children"
                label-for="children"
              >
                <b-form-input
                  id="children"
                  v-model="Children"
                  placeholder=""
                  name="children"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-1">
            <b-col>
              <b-form-group
                label="Race"
                label-for="race"
              >
                <b-form-input
                  id="race"
                  v-model="Race"
                  placeholder=""
                  name="race"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Hair Color"
                label-for="hairColor"
                class="text-nowrap"
              >
                <b-form-input
                  id="hairColor"
                  v-model="Hair"
                  placeholder=""
                  name="hairColor"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Hair Style"
                label-for="hairStyle"
                class="text-nowrap"
              >
                <b-form-input
                  id="hairStyle"
                  v-model="HairStyle"
                  placeholder=""
                  name="hairStyle"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Eye Color"
                label-for="eyeColor"
                class="text-nowrap"
              >
                <b-form-input
                  id="eyeColor"
                  v-model="Eyes"
                  placeholder=""
                  name="eyeColor"
                />
              </b-form-group></b-col>
            <b-col>
              <b-form-group
                label="Glasses"
                label-for="glasses"
              >
                <b-form-input
                  id="glasses"
                  v-model="Glasses"
                  placeholder=""
                  name="glasses"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Other Characteristics (if different than above)"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedOtherCharacteristics}"
            >
              <textarea
                v-model="OtherCharacteristics"
                class="expandable-textarea"
                :class="[isExpandedOtherCharacteristics ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedOtherCharacteristics = !isExpandedOtherCharacteristics">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedOtherCharacteristics ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1 pb-1">
        <b-col>
          <b-form-group
            label="Known Vehicles"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedKnownVehicles}"
            >
              <textarea
                v-model="VehicleInformation"
                class="expandable-textarea"
                :class="[isExpandedKnownVehicles ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedKnownVehicles = !isExpandedKnownVehicles">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedKnownVehicles ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      SubjectDescription: null,

      SexMale: false,
      SexFemale: false,
      SexUnknown: false,

      Gender: "",
      Height: "",
      Weight: "",

      Race: "",
      Hair: "",
      HairStyle: "",
      Eyes: "",
      Glasses: "",
      OtherCharacteristics: "",
      VehicleInformation: "",
      Spouse: "",
      Children: "",

      HeightFt: 0,
      HeightIn: 0,

      sexOptions: ["Male", "Female"],

      isExpandedKnownVehicles: false,
      isExpandedOtherCharacteristics: false,
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "publicCase/getSubject",
      getSubjectAddress: "publicCase/getSubjectAddress",
    }),
    getSubjectData() {
      return this.getSubject
    },
  },
  watch: {
    getSubjectData(val) {this.fillSubject(val);},
    SexMale(val) {this.setGender(val, "Male")},
    SexFemale(val) {this.setGender(val, "Female")},
    SexUnknown(val) {this.setGender(val, "Unknown")},
    Gender(val) {this.SubjectDescription = {...this.SubjectDescription, Gender: val}},
    Weight(val) {this.SubjectDescription = {...this.SubjectDescription, Weight: val}},
    Race(val) {this.SubjectDescription = {...this.SubjectDescription, Race: val}},
    Hair(val) {this.SubjectDescription = {...this.SubjectDescription, Hair: val}},
    HairStyle(val) {this.SubjectDescription = {...this.SubjectDescription, HairStyle: val}},
    Eyes(val) {this.SubjectDescription = {...this.SubjectDescription, Eyes: val}},
    Glasses(val) {this.SubjectDescription = {...this.SubjectDescription, Glasses: val}},
    OtherCharacteristics(val) {this.SubjectDescription = {...this.SubjectDescription, OtherCharacteristics: val}},
    VehicleInformation(val) {this.SubjectDescription = {...this.SubjectDescription, VehicleInformation: val}},
    Children(val) {this.SubjectDescription = {...this.SubjectDescription, Children: val}},
    Spouse(val) {this.SubjectDescription = {...this.SubjectDescription, Spouse: val}},

    HeightFt() {this.fillHeight()},
    HeightIn() {this.fillHeight()},

    SubjectDescription(val) {
      this.$emit("changedSubjectDescription", {...val, SubjectAddress: this.getSubjectAddress});
    },
  },
  async created() {
    this.fillSubject(this.getSubject);
  },
  methods: {
    fillSubject(val) {
      this.Gender = val.Gender;
      this.Height = val.Height;
      this.Weight = val.Weight;
      this.Spouse = val.Spouse;
      this.Children = val.Children;
      this.Race = val.Race;
      this.Hair = val.Hair;
      this.HairStyle = val.HairStyle;
      this.Eyes = val.Eyes;
      this.Glasses = val.Glasses;
      this.OtherCharacteristics = val.OtherCharacteristics;
      this.VehicleInformation = val.VehicleInformation;
      this.splitHeight();

    },
    splitHeight() {
      if (this.Height && this.Height.indexOf(" ft ") !== -1) {
        const heightAr = this.Height.split(" ft ");
        this.HeightFt = heightAr[0];
        this.HeightIn = heightAr[1];
      }
    },
    fillHeight() {
      this.Height = this.HeightFt + " ft " + this.HeightIn;
      this.SubjectDescription = {...this.SubjectDescription, Height: this.Height}
    },
    setGender(val, name) {
      if (val && name) {
        if (this.Gender) this["Sex" + this.Gender] = false
        this.Gender = name;
      } else if (name === this.Gender && !val) {
        this.Gender = ""
      }
    }
  },
}
</script>

<style scoped>
.height-input input {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 3.5rem;
}
p {
  font-size: .857rem;
}
</style>