import { render, staticRenderFns } from "./SubjectAddress.vue?vue&type=template&id=4ae0fcdb&scoped=true&"
import script from "./SubjectAddress.vue?vue&type=script&lang=js&"
export * from "./SubjectAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae0fcdb",
  null
  
)

export default component.exports